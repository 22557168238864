import React from "react";
import theme from "theme";
import { Theme, Link, Text, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { Override, Section, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<Helmet>
			<title>
				About - kursin.com - modern web development
			</title>
			<meta name={"description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:title"} content={"About - kursin.com - modern web development"} />
			<meta property={"og:description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1589561253898-768105ca91a8?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
			<script async src={`https://www.googletagmanager.com/gtag/js?id=UA-176560010-1`}></script>
     		<script>
			{`
      	    window.dataLayer = window.dataLayer || [];
          	function gtag(){dataLayer.push(arguments);}
          	gtag('js', new Date());
          	gtag('config', "UA-176560010-1");
        	`}
		   	</script>
		</Helmet>
		<Components.Header />
		<Section
			sm-padding="0px 0 0px 0"
			quarkly-title="About"
			box-sizing="border-box"
			padding="50px 0px 50px 0px"
			background="--color-lightD1"
			border-color="--color-lightD2"
			border-style="solid"
			border-width="1px 0px 1px 0px"
			lg-padding="50px 0px 50px 0px"
			md-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				margin="0px 32px 0px 32px"
				sm-padding="24px 0px 24px 0px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				text-align="center"
				font="--base"
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				color="--dark"
				lg-margin="0px 0px 6px 0px"
				lg-text-align="center"
				quarkly-title="Title"
				text-transform="uppercase"
			>
				Who we are
			</Text>
			<Text
				font="normal 300 28px/1.2 --fontFamily-googleMontserrat"
				margin="0px 0px 0px 0px"
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
			>
				Team of specialists in modern NoCode tools like Bubble and experienced full stack developers
			</Text>
		</Section>
		<Section
			box-sizing="border-box"
			padding="50px 0px 50px 0px"
			quarkly-title="Our Services"
			xl-padding="100px 0px 100px 0px"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				font="--base"
				margin="0px 0px 10px 0px"
				letter-spacing="1px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				text-transform="uppercase"
				lg-margin="0px 0px 6px 0px"
				lg-text-align="center"
				quarkly-title="Title"
			>
				What we are best at
			</Text>
			<Text
				font="normal 300 38px/1.2 --fontFamily-googleMontserrat"
				margin="0px 0px 72px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
				lg-margin="0px 0px 48px 0px"
				md-font="normal 300 28px/1.2 --fontFamily-googleMontserrat"
				sm-margin="0px 0px 36px 0px"
			>
				Our Services
			</Text>
			<Stack width="100%" sm-margin="-16px 0px -16px 0px">
				{"    "}
				<StackItem
					width="33.33%"
					display="flex"
					lg-width="50%"
					margin="0px 0px 24px 0px"
					md-margin="0px 0px 0px 0px"
					md-width="100%"
				>
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image width="50px" height="50px" background="rgba(0, 0, 0, 0)" src="https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f8709df375c37f4b370cd91_idea1.svg?v=2020-12-02T14:43:58.491Z" />
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Safety of your idea
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
					>
						The safety of your idea is our priority. We sign a non-disclosure agreement with you. You can be sure that no one else will know anything about project development.
					</Text>
					{"    "}
				</StackItem>
				<StackItem
					width="33.33%"
					display="flex"
					lg-width="50%"
					margin="0px 0px 24px 0px"
					md-margin="0px 0px 0px 0px"
					md-width="100%"
				>
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image width="50px" height="50px" background="rgba(0, 0, 0, 0)" src="https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f8709df42ebdd39a1d38aad_time.svg?v=2020-12-02T14:43:58.484Z" />
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Accurate estimates
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
					>
						We provied the most accurate estimate of the cost of all works. You will not pay more than the agreed amount for the services we have agreed upon.
					</Text>
					{"    "}
				</StackItem>
				<StackItem
					width="33.33%"
					display="flex"
					lg-width="50%"
					margin="0px 0px 24px 0px"
					md-margin="0px 0px 0px 0px"
					md-width="100%"
				>
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image width="50px" height="50px" background="rgba(0, 0, 0, 0)" src="https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f87083906b087789b9cbd96_progres.svg?v=2020-12-02T14:43:58.496Z" />
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Information on progress
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
					>
						You will always be aware of the stage of the project at the moment. Almost at any time you can look at the current result.
					</Text>
					{"    "}
				</StackItem>
				<StackItem
					width="33.33%"
					display="flex"
					lg-width="50%"
					margin="0px 0px 24px 0px"
					md-margin="0px 0px 0px 0px"
					md-width="100%"
				>
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image width="50px" height="50px" background="rgba(0, 0, 0, 0)" src="https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f8709de032d401231df0404_document.svg?v=2020-12-02T14:43:58.484Z" />
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Project documentation
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
					>
						At your request, the documentation will be provided for further support of the project by yourself or another specialist.
					</Text>
					{"    "}
				</StackItem>
				<StackItem
					width="33.33%"
					display="flex"
					lg-width="50%"
					margin="0px 0px 24px 0px"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
				>
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image width="50px" height="50px" background="rgba(0, 0, 0, 0)" src="https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f870839ebbdc546da4800fe_chat.svg?v=2020-12-02T14:43:58.491Z" />
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Long-time support
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
					>
						We offer services for further support or future project development. Just don't forget to specify the cost of support at the end of the project.
					</Text>
					{"    "}
				</StackItem>
				<StackItem
					width="33.33%"
					display="flex"
					lg-width="50%"
					margin="0px 0px 24px 0px"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
				>
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Image width="50px" height="50px" background="rgba(0, 0, 0, 0)" src="https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f870839f1fdaa63b054083e_idea.svg?v=2020-12-02T14:43:58.724Z" />
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Understanding your idea
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
					>
						We understand your idea as if it was ours. That's why we treat your project with such care as if it were our project.
					</Text>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section
			box-sizing="border-box"
			padding="80px 0px 80px 0px"
			quarkly-title="About"
			background="--color-lightD1"
			border-width="1px 0px 1px 0px"
			border-style="solid"
			border-color="--color-lightD2"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Stack>
				{"    "}
				<StackItem width="100%" display="flex" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" align-items="center" />
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="left"
						text-align="left"
					>
						Many years ago we started to learn interesting things which are now called NoCode. Sometimes our customer's projects were so big that we had to work on them for months. But it was worth it.
						<br />
						<br />
						But now modern NoCode tools have become so flexible and convenient that it is much more pleasant and faster to work. Each project goes through unique stages of development, which are inherent only to him. We try to help our clients to understand the details if they are interested in them.
						<br />
						‍
						<br />
						Sometimes our clients come with only one idea and small sketches, but even in this case we are able to help them to draw up the terms of reference or product requirements document and make a high-level design.
					</Text>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Components.Upfooter />
		<Components.FooterMain>
			<Override slot="stackItem" lg-width="100%" sm-width="100%" />
		</Components.FooterMain>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
	</Theme>;
});